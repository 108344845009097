<template>
  <div class="input_field quick_link_block">

    <b-modal modal-class="modal_basic modal_basic_seo_preview modal-seo-preview" id="seo-preview" scrollable hide-footer
             hide-header>

      <div class="heading">
        <h3 class="d-inline-block title">
          <template>
            Preview
          </template>
        </h3>
        <img @click="closeQuickLinkModal()" class="close_modal" src="/assets/img/cross.svg" alt="">
      </div>
      <div class="modal_content" v-if="previewCall == 'seo_preview' && getQuickLink.seo">
        <img :src="getQuickLink.seo.image ? getQuickLink.seo.image : '/assets/img/notfound.png'">
        <div class="heading">
          <div class="title" v-if="getQuickLink.seo && getQuickLink.seo.title == ''">Meta Title</div>
          <div class="title" v-else-if="getQuickLink.seo">{{ getQuickLink.seo.title }}</div>
          <div class="description" v-if="getQuickLink.seo && getQuickLink.seo.description == ''">Meta
            Description
          </div>
          <div class="description" v-else-if="getQuickLink.seo">{{ getQuickLink.seo.description }}</div>
        </div>
      </div>
      <div class="modal_content" v-else>
        <img :src="getQuickLink.custom_favicon_url ? getQuickLink.custom_favicon_url : '/assets/img/notfound.png'">
      </div>
    </b-modal>

    <b-modal modal-class="modal_basic customDomainModal inline_style_modal"
             id="quickLinkModal" scrollable
             no-close-on-backdrop
             hide-footer
             @hide="closeQuickLinkModal()"
             hide-header>
      <img @click="$bvModal.hide('quickLinkModal')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            {{ getSiteDetails.agency_name }} Link
          </h3>
        </div>
        <div class="box_content">
          <template>
            <!--            <create-quick-link location="dashboard_shortner_form"></create-quick-link>-->
            <div class="shorten_form_block text-center dashboard_shortner_form" v-if="getQuickLink._id">
              <!--        <img class="close_box" src="/img/cancel.png"-->
              <!--             @click.prevent="$store.commit('SET_QUICK_LINK_DEFAULT_VALUE')" alt="">-->
              <div class="shorten_form_block_inner ">

                <div :title="isTrialUserObj.message" class="mt-3 mb-3 share_link_block d-flex align-items-center clear">
                  <div class="link_block d-inline-flex" :class="{'active': active_input}">

                    <span>{{getQuickLink.domain.url }}</span>

                    <span v-if="!edit_slug" v-tooltip="getQuickLink.shorten_url"
                          class="text link_url">{{ getQuickLink.shorten_url }}</span>
                    <input v-else @focus="active_input = true"
                           style="width: 150px"
                           ref="editSlugField"
                           @blur="active_input = false" class="editable_url"
                           type="text" v-model="slugValue"
                    >
                    <input type="hidden" id="copy-url"
                           :value="copyUrl(getQuickLink.shorten_url,getQuickLink.domain.url)">
                    <span style="margin-left: auto;">
                        <i style="float: right; margin-top: 5px;" v-if="!edit_slug" class="fa fa-files-o"
                           title="" @click.stop.prevent="copyUrlName"></i>
                        <i v-show="!isTrialUserObj.isTrial" style="margin-top: 5px; margin-right: 10px"
                           v-if="!edit_slug"
                           @click.prevent="editQuickLinkSlug()" class="fa fa-pencil"></i>
                        <i style="margin-top: 5px; margin-right: 10px" v-if="edit_slug"
                           @click="editSlugForQuickLink()" class="fa fa-check"></i>
                        <i style="margin-top: 5px;" v-if="edit_slug" @click="edit_slug = false"
                           class="fa fa-times"></i>

                    </span>
                  </div>
                </div>

                <div class="mt-3 mb-3 input_icon_text d-flex align-items-center profile__box tags_dd">

                  <div class="avatar">
                    <i class="fal fa-tag"></i>
                  </div>

                  <div class="content">
                    <div class="url">Add tags (optional)</div>
                    <div class="name">
                      <input maxlength="50"
                             v-on:keyup="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag.trim())"
                             @focus="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag.trim())"
                             @blur="blurFocusQuickTag()"
                             type="text"
                             v-model="getQuickLinkTag.tag"
                             placeholder="Enter tag name...">
                      <div class="tag_list_dd w-50"
                           v-if="getQuickLinkTag.tag.length > 0 || getQuickLinkTag.focus === true">
                        <ul class=""
                            v-if="getQuickLinkTag.tag.length > 0 && checkTagNotExist(getQuickLinkTag.tag,getQuickLink.tags)">
                          <template
                            v-if="getLinks.suggestedTags.length > 0">
                            <li class="old_item"
                                v-for="tag in getLinks.suggestedTags">
                                                                            <span
                                                                              @click.prevent="addLinkQuickTag($event,getQuickLink._id,getTagName(tag._id),true)"
                                                                              class="text">{{
                                                                                getTagName(tag._id)
                                                                              }}</span>
                            </li>
                          </template>
                          <li v-else class=" new_item">
                                                                        <span
                                                                          @click.prevent="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag,true)"
                                                                          class="text">Add new tag "{{
                                                                            getQuickLinkTag.tag
                                                                          }}"</span>
                          </li>
                        </ul>
                        <ul class=""
                            v-else-if="getQuickLinkTag.tag.length === 0 && getQuickLinkTag.focus === true && getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
                          <li class="old_item"
                              v-for="tag in getLinks.suggestedTags">
                                    <span class="text"
                                          @click.prevent="addLinkQuickTag($event,getQuickLink._id,getTagName(tag._id),true)">
                                        {{ getTagName(tag._id) }}
                                    </span>
                          </li>
                        </ul>

                      </div>

                    </div>

                  </div>

                </div>

                <div class="mt-3 text-justify tag-item-list">
                  <ul>
                    <!--          <template v-if="getLinksLoaders.quick_link">-->
                    <!--            <clip-loader :color="'#168eea'"-->
                    <!--                         :size="'#14px'"></clip-loader>-->
                    <!--          </template>-->
                    <template v-if="!getLinksLoaders.quick_link">
                      <li v-for="(tag,tag_index) in getQuickLink.tags">
                        <span class="text">{{ getTagName(tag) }}</span>
                        <span class="cross_icon"
                              @click.prevent="removeLinkTag(getQuickLink._id,tag_index,tag,true)"></span>
                      </li>
                    </template>

                    <li class="add_tag d-none">
                      <span class="cross_icon add_icon"></span>
                      <span class="text">add</span>

                    </li>
                  </ul>
                </div>

                <div class="d-flex mt-3 mb-3">
                  <div class="col-md-6 col-lg-6 input_icon_text" style="display: inline-table">
                    <div class="d-flex justify-content-between setting_item mb-3">
                      <div class="text_sec">
                        <p>Customize Link Preview</p>
                      </div>
                      <div class="status_sec" v-tooltip="!getQuickLink.iframe_allowed ? 'Toggle disabled in case of summary page' : ''" style="float: right">
                        <label class="switch">
                          <input type="checkbox" :disabled="!getQuickLink.iframe_allowed" v-model="sectionsDisplayChecks.showCustomMeta" class="d-none" @click="setCustomMeta()">
                          <div class="slider round" :class="{'opacity-60': !getQuickLink.iframe_allowed}"></div>
                        </label>
                      </div>
                    </div>
                    <template v-if="getLinksLoaders.customizeLinkPreviewLoader">
                      <clip-loader :size="'22px'" :color="'#3988fe'"></clip-loader>
                      <p class="text-center text-muted">Scrapping data...</p>
                    </template>
                    <template v-else>
                      <!--meta title-->
                      <div class="d-flex align-items-center url_input_main "
                           v-if="sectionsDisplayChecks.showCustomMeta">
                        <div class="content">
                          <div class="url">
                            Meta Title
                          </div>
                          <div class="name">
                            <div class="validation_input">
                              <input
                                placeholder="Enter the meta title"
                                name="link_seo_title"
                                v-model="getQuickLink.seo.title" type="text"
                                class="form-control txt-left-left">
                            </div>
                            <span class="input-error"
                                  v-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.title">{{
                                customizeLinkPreviewMessages.title
                              }}</span>
                            <span class="input-error"
                                  v-else-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.titleLength">{{
                                customizeLinkPreviewMessages.titleLength
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <!--meta description-->
                      <div class="d-flex align-items-center url_input_main "
                           v-if="sectionsDisplayChecks.showCustomMeta">
                        <div class="content">
                          <div class="url">
                            Meta Description
                          </div>
                          <div class="name">
                            <div class="validation_input">
                              <input
                                placeholder="Enter the meta description"
                                name="link_seo_title"
                                v-model="getQuickLink.seo.description" type="text"
                                class="form-control txt-left-left">
                            </div>
                            <span class="input-error"
                                  v-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.description">{{
                                customizeLinkPreviewMessages.description
                              }}</span>
                            <span class="input-error"
                                  v-else-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.descriptionLength">{{
                                customizeLinkPreviewMessages.descriptionLength
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <!--meta image-->
                      <div class="d-flex align-items-center url_input_main "
                           v-if="sectionsDisplayChecks.showCustomMeta">
                        <div class="content">
                          <div class="url">
                            Meta Image URL
                          </div>
                          <div class="name">
                            <div class="validation_input">
                              <input
                                placeholder="Enter the meta image URL (200 x 200 px) or more"
                                name="link_seo_title"
                                v-model="getQuickLink.seo.image" type="text"
                                class="form-control txt-left-left">
                            </div>
                            <span class="input-error"
                                  v-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.url">{{
                                customizeLinkPreviewMessages.url
                              }}</span>
                            <span class="input-error"
                                  v-else-if="sectionsDisplayChecks.showCustomMeta && customizeLinkPreviewValidations.urlLength">{{
                                customizeLinkPreviewMessages.urlLength
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <!--upload meta image and preview-->
                      <div class="d-flex align-items-center url_input_main "
                           v-if="sectionsDisplayChecks.showCustomMeta">
                        <div class="content">
                          <div class="name">
                            <div class="validation_input">
                              <input
                                id="custom_meta_image"
                                placeholder="Enter the meta image url"
                                type="file"
                                @change="uploadMetaImage($event)"
                                accept=".png, .jpg, .jpeg, .gif"
                                class="form-control txt-left-left d-none">
                              <label for="custom_meta_image" class="upload-meta-image">
                                <i class="fa fa-upload" v-if="!getLoaders.custom_meta_image"
                                   aria-hidden="true"></i>
                                <clip-loader v-if="getLoaders.custom_meta_image" class="loader" :size="size"
                                             :color="colorBlue"></clip-loader>
                                <span>Upload Image</span>
                              </label>
                              <div class="upload-meta-preview d-inline-block"
                                   @click="showPreview('seo_preview')">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                                <span>Preview</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </template>
                  </div>
                  <div class="p-1 offset"></div>
                  <div class="col-md-6 col-lg-6 input_icon_text" style="display: inline-table">
                    <div class="d-flex justify-content-between setting_item mb-3">
                      <div class="text_sec">
                        <p>Customize Favicon</p>
                      </div>
                      <div class="status_sec" v-tooltip="!getQuickLink.iframe_allowed ? 'Toggle disabled in case of summary page' : ''" style="float: right">
                        <label class="switch">
                          <input type="checkbox" :disabled="!getQuickLink.iframe_allowed" class="d-none" v-model="sectionsDisplayChecks.showCustomFavicon" @click="setCustomFavicon()">
                          <div class="slider round" :class="{'opacity-60': !getQuickLink.iframe_allowed}"></div>
                        </label>
                      </div>
                    </div>

                    <!--favicon image-->
                    <div class="d-flex align-items-center url_input_main "
                         v-if="sectionsDisplayChecks.showCustomFavicon">
                      <div class="content">
                        <div class="url">
                          Custom Favicon URL
                        </div>
                        <div class="name">
                          <div class="validation_input">
                            <input
                              placeholder="Enter the favicon image URL (16 x 16 recommended)"
                              name="link_seo_title"
                              v-model="getQuickLink.custom_favicon_url" type="text"
                              class="form-control txt-left-left">
                          </div>
                          <span class="input-error" v-if="sectionsDisplayChecks.showCustomFavicon && invalidFavicon">
                            Custom Favicon URL is required.
                    </span>
                        </div>
                      </div>
                    </div>


                    <!--upload meta image and preview-->
                    <div class="d-flex align-items-center url_input_main "
                         v-if="sectionsDisplayChecks.showCustomFavicon">
                      <div class="content">
                        <div class="name">
                          <div class="validation_input">
                            <input
                              id="custom_favicon_image"
                              placeholder="Enter the favicon image url (16 x 16 recommended)"
                              type="file"
                              @change="uploadCustomFaviconImage($event)"
                              accept=".png, .jpg, .jpeg, .gif"
                              class="form-control txt-left-left d-none">
                            <label for="custom_favicon_image" class="upload-meta-image">
                              <i class="fa fa-upload" v-if="!getLoaders.custom_favicon_image_url"
                                 aria-hidden="true"></i>
                              <clip-loader v-if="getLoaders.custom_favicon_image_url" class="loader" :size="size"
                                           :color="colorBlue"></clip-loader>
                              <span>Upload Image</span>
                            </label>
                            <div class="upload-meta-preview d-inline-block"
                                 @click="showPreview('custom_favicon')">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              <span>Preview</span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="d-flex mt-3">
                  <div class="col-md-6 col-lg-6 p-0 input_icon_text" style="display: inline-table">
                    <password-protected-component
                      @passwordProtectedObj="passwordProtectedObj($event)"
                      :password_protected="this.getQuickLink.password_protected"
                      :password="this.getQuickLink.password"
                      :password_protected_logo="this.getQuickLink.password_protected_logo"
                      :self="this"
                      :passwordProtectedValidations="passwordProtectedValidations"
                      :called="false"
                    ></password-protected-component>
                  </div>
                  <div class="p-1 offset"></div>
                  <div class="col-md-6 col-lg-6 input_icon_text" style="display: inline-table">
                    <div class="d-flex justify-content-between setting_item mb-3">
                      <div class="text_sec">
                        <p>Link Expiry</p>
                      </div>
                      <div class="status_sec" style="float: right">
                        <label class="switch">
                          <input type="checkbox" class="d-none" @click="setLinkExpiry()">
                          <div class="slider round"></div>
                        </label>
                      </div>
                    </div>

                    <div class="d-flex align-items-center url_input_main "
                         v-if="sectionsDisplayChecks.showLinkExpiry">

                      <div class="content">
                        <div class="url">
                          Date
                        </div>
                        <div class="name">
                          <div class="validation_input">
                            <b-form-datepicker id="example-datepicker" :min="min"
                                               v-model="getQuickLink.link_expire_attributes.expiry"
                                               class="data-picker"></b-form-datepicker>
                          </div>
                        </div>
                        <span class="input-error"
                              v-if="sectionsDisplayChecks.showLinkExpiry && linkExpireAttributesValidations.expiry">{{
                            linkExpireAttributesMessages.expiry
                          }}</span>
                      </div>

                    </div>
                    <div class="d-flex align-items-center url_input_main "
                         v-if="sectionsDisplayChecks.showLinkExpiry">

                      <div class="content">
                        <div class="url">
                          Time
                        </div>
                        <div class="name">
                          <div class="validation_input">
                            <b-form-timepicker class="time-picker" id="datepicker-valid"
                                               v-model="getQuickLink.link_expire_attributes.expiry_time"></b-form-timepicker>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-if="isAgency && sectionsDisplayChecks.showLinkExpiry">
                      <div class=" mb-1 mt-1 d-flex align-items-center url_input_main ">
                        <div class="content">
                          <div class="url">
                            Upload Link Expiry Image URL
                          </div>
                          <div class="name">
                            <div class="validation_input">
                              <input
                                placeholder="Enter the favicon image url (16 x 16 recommended)"
                                v-model="getQuickLink.link_expire_attributes.link_expiry_logo" type="text"
                                class="form-control txt-left-left">
                            </div>
                            <span class="input-error"
                                  v-if="sectionsDisplayChecks.showLinkExpiry && linkExpireAttributesValidations.url">{{
                                linkExpireAttributesMessages.url
                              }}</span>
                            <span class="input-error"
                                  v-else-if="sectionsDisplayChecks.showLinkExpiry && linkExpireAttributesValidations.urlLength">{{
                                linkExpireAttributesMessages.urlLength
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class=" mb-3 d-flex align-items-center url_input_main ">
                        <div class="content">
                          <div class="name">
                            <div class="validation_input">
                              <input
                                id="link_expiry_logo"
                                placeholder="Enter the favicon image url (16 x 16 recommended)"
                                type="file"
                                @change="uploadLinkExpiryLogo($event)"
                                accept=".png, .jpg, .jpeg, .gif, .ico"
                                class="form-control txt-left-left d-none">
                              <label for="link_expiry_logo" class="upload-meta-image">
                                <i class="fa fa-upload" v-if="!getLoaders.link_expiry_logo"
                                   aria-hidden="true"></i>
                                <clip-loader v-if="getLoaders.link_expiry_logo" class="loader" :size="size"
                                             :color="colorBlue"></clip-loader>
                                <span>Upload Image</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex text-justify">
                  <div class="col-md-6 p-0 col-lg-6 input_icon_text mt-3" style="display: inline-table;height: 0px">
                    <traffic-routing-rules-component
                      v-model="getQuickLink.is_routing_rule"
                      @input="showTrafficRoutingRules($event)"
                      :routing_rules="getQuickLink.routing_rules"
                      :campaign_id="getQuickLink.call_to_action_id"
                      :self="this"
                      :is_ab_test="getQuickLink.is_ab_test"
                      :traffic_routing_cta_failed="trafficRoutingCTAFailed"
                      :traffic_routing_iframe_loader="trafficRoutingIframeLoader"
                      :check_iframe_error="checkTrafficRoutingIframeErrorCount"
                      :is_called_by_dashboard="true"
                    ></traffic-routing-rules-component>
                  </div>
                  <div class="p-1 offset"></div>
                  <div class="col-md-6 p-0 col-lg-6 input_icon_text mt-3" style="display: inline-table;height: 0px">
                    <ab-testing-component
                      :split_urls="getQuickLink.split_urls"
                      :campaignId="getQuickLink.call_to_action_id"
                      :campaign="getQuickLink.cta"
                      :url="getQuickLink.url"
                      :self="this"
                      @input="showABTesting($event)"
                      :is_ab_test="getQuickLink.is_ab_test"
                      :is_routing_rule="getQuickLink.is_routing_rule"
                      :is_called_by_dashboard="true"
                    />
                  </div>
                </div>

                <div class="d-flex text-justify">
                  <div class="col-md-6 p-0 col-lg-6 input_icon_text mt-3" style="display: inline-table;height: 0px">
                    <deep-links-component
                      v-model="getQuickLink.is_deep_link_enabled"
                      :is_deep_link_allowed="getQuickLink.is_deep_link_allowed"
                      :url="getQuickLink.url"
                      :deepLinksBrandsList="deepLinksBrandsList"
                      :self="this"
                      :campaignId="getQuickLink.call_to_action_id"
                      :is_called_by_dashboard="true"
                    ></deep-links-component>
                  </div>
                  <div class="p-1 offset"></div>
                </div>


                <div class="d-flex mt-3" :class="{'mb-3': getLinksLoaders.quick_link}">
                  <div class="col-md-12 col-lg-12 text-center">
                    <transition name="fade">
                      <button @click="update()"
                              :disabled="getLinksLoaders.quick_link || trafficRoutingIframeLoader"
                              class="mt-3 btn---cta btn-blue with-shadow btn-round  btn-bold">
                        <span>Update</span>
                        <span class="icon_right">
                <clip-loader v-if="getLinksLoaders.quick_link"
                             style="margin-top: 4px;"
                             :color="'#ffffff'"
                             :size="'14px'"></clip-loader>
              </span>
                      </button>
                    </transition>

                  </div>
                </div>

                <div class="mt-5 mb-3   tag-item-list " v-if="false">

                  <ul>
                    <template v-if="getLinksLoaders.quick_link">
                      <clip-loader :color="'#168eea'"
                                   :size="'#14px'"></clip-loader>
                    </template>
                    <template v-else>
                      <li v-for="(tag,tag_index) in getQuickLink.tags">
                        <span class="text">{{ getTagName(tag) }}</span>
                        <span class="cross_icon"
                              @click.prevent="removeLinkTag(getQuickLink._id,tag_index,tag,true)"></span>
                      </li>
                    </template>

                    <li class="add_tag d-none">
                      <span class="cross_icon add_icon"></span>
                      <span class="text">add</span>

                    </li>
                  </ul>
                </div>
                <div class="social-networks text-center mt-4">
                  <p>
                    Now go ahead and share this optimized {{ getSiteDetails.agency_name }}
                    link with your audience on social, email, web or any other
                    channel you want.
                  </p>
                  <social-sharing class="circle_social"
                                  :url="socialUrl(getQuickLink.shorten_url,getQuickLink.domain.url)"
                                  inline-template>
                    <div>
                      <network network="facebook">
                        <i class="fab fa-fw fa-facebook fb_bg"></i>
                      </network>
                      <network network="twitter">
                        <i class="fab fa-fw fa-twitter tw_bg"></i>
                      </network>
                      <network network="googleplus">
                        <i class="fab fa-fw fa-google-plus gplus_bg"></i>
                      </network>
                      <network network="linkedin">
                        <i class="fab fa-fw fa-linkedin lin_bg"></i>
                      </network>
                      <network network="pinterest">
                        <i class="fab fa-fw fa-pinterest pin_bg"></i>
                      </network>
                      <network network="reddit">
                        <i class="fab fa-fw fa-reddit reddit_bg"></i>
                      </network>
                    </div>
                  </social-sharing>
                </div>

                <div class="text-center mt-3">

                  <a class="another_link" @click.prevent="close()">Create
                    Another {{ getSiteDetails.agency_name }} Link</a>
                  <!--                <button @click.prevent="$store.commit('SET_QUICK_LINK_DEFAULT_VALUE')"-->
                  <!--                        class="mt-3 btn-&#45;&#45;cta btn-blue with-shadow btn-round  btn-bold">-->
                  <!--                    <span>Create Another Link</span>-->
                  <!--                    <span class="icon_right">-->
                  <!--                       <i-->
                  <!--                               class="circle_icon d-flex align-items-center justify-content-center  fal fa-arrow-right  "></i>-->
                  <!--                    </span>-->
                  <!--                </button>-->

                </div>
              </div>

            </div>
          </template>
        </div>

      </div>
    </b-modal>

    <!-- Campaign dropdown -->
    <b-dropdown right ref="campaigns_dropdown_quick_link"
                class="createLink_dropdown_dashboard radio_dropdown dropdown-menu-right default_style_dropdown"
                :no-caret="true">
      <div @click="fetchCampaignOnDropdown" class="text-center dropdown_header no_arrow" slot="button-content"
           :class="{'input-field-error':validations.campaign}">
        <div class="text">
          <div class="d-flex align-items-center profile__box">
            <div class="avatar">
              <i class="fal fa-folder"></i>
            </div>
            <div class="content">
              <div class="url">Campaigns</div>
              <div class="name">
                <p v-if="!getCampaignName(getQuickLink.call_to_action_id)">Select your campaign</p>
                <p v-else>{{ limitTextLength(getCampaignName(getQuickLink.call_to_action_id).name, 45) }}</p>
              </div>
            </div>
          </div>
        </div>
        <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
      </div>
      <ul id="campaign-dropdown-scroll"
          @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')"
          class="clear inner">
        <template v-if="getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7 || getCampaigns.search">
          <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation">
            <input placeholder="Search for campaign"
                   type="text"
                   class="search_input_block_for_dropdown dropdown_header with-shadow rounded_shape"
                   :value="getCampaigns.search" @input="debounceCampaignDropdownSearch" data-cy="search-camp"> <i
            class="fal fa-search"></i>
          </div>
          <hr>
        </template>
        <template v-if="getCampaignsList && getCampaignsList.length">
          <li class="list_item_li" v-for="cta in getComponentCampaignList">
            <div class="radio_input_image ">
              <input type="radio" :id="cta._id"
                     :checked="cta._id === getQuickLink.call_to_action_id"
                     @click="selectCampaignForQuickLink(cta), $refs.campaigns_dropdown_quick_link.hide(true)"
                     v-if="cta.name">
              <label :for="cta._id" class="radio_right">
                <div class="d-flex align-items-center profile__box">
                  <div class="avatar">
                    <div class="brand_img"
                         :style="brandImageLink(cta.brand.avatar)"></div>
                  </div>
                  <div class="content">
                    <div class="name">
                      {{ limitTextLength(cta.name, 45) }}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </li>
          <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
        </template>
        <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
          <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
        </li>
        <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length" class=" no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}</li>
        <li v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length" class=" no_text_li">{{ searchFiltersMessages('campaigns').empty_list_dropdown }}</li>
      </ul>
    </b-dropdown>
    <span class="input-error" v-if="validations.campaign && !getCampaignLoaders.retrieve">{{ messages.campaign }}</span>
    <!-- Campaign drop down section end  -->
    <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
         :class="{'input-field-error': validations.url || validations.urlValid || validations.urlLength}">
      <div class="avatar">
        <i class="fal fa-link"></i>
      </div>
      <div class="content">
        <div class="url">URL to shorten</div>
        <div class="name">
          <div class="validation_input">
            <url
              v-model="getQuickLink.url"
              @onEnter="validateAndStoreQuickLink($event)"
              :validations="validations"
              v-bind:blur="() => getQuickLink.url = focusoutConcatenation(getQuickLink.url)"
              ref="urlRef"
              placeholder="Enter URL of the web page you want to optimize and share..."></url>
          </div>

        </div>
      </div>
      <div class="avatar mr-0 text-right" style="min-width: 85px;">
        <apply-utm-link v-if="getQuickLink.url" position="dashboard_place"></apply-utm-link>
      </div>
    </div>
    <span class="input-error" v-if="validations.url">{{ messages.url }}</span>
    <span class="input-error" v-else-if="validations.urlLength">{{ messages.urlLength }}</span>
    <span class="input-error" v-else-if="validations.urlValid">{{ messages.urlValid }}</span>

    <div class="text-center">

      <button :disabled="getLinksLoaders.quick_link" @click="validateAndStoreQuickLink()"
              class="mt-3 btn---cta btn-blue with-shadow btn-round  btn-bold">
        <span>Create</span>
        <span class="icon_right">
                     <clip-loader v-if="getLinksLoaders.quick_link"
                                  style="margin-top: 4px;"
                                  :color="'#ffffff'"
                                  :size="'14px'"></clip-loader>
                   <i v-else
                      class="circle_icon d-flex align-items-center justify-content-center  fal fa-arrow-right  "></i>
                </span>
      </button>

    </div>
    <!--</template>-->
  </div>
</template>
<script>
import ApplyUTMLink from '../setting/utm/ApplyUTMLink'
import { mapActions, mapGetters } from 'vuex'
import { campaignTypes, linkTypes } from '@/state/modules/mutation-types'
import URL from '@/ui/URL.vue'
import {
  quickLinksValidations,
  quickLinksMessages,
  passwordProtectedValidations,
  customizeLinkPreviewValidations,
  customizeLinkPreviewMessages,
  linkExpireAttributesMessages,
  linkExpireAttributesValidations,
  slugMessages,
  searchFiltersMessages, callTypeList
} from '../../../common/attributes'
import CreateQuickLink from '@/views/pages/links/CreateQuickLink'
import ABTestingComponent from '@/views/pages/links/links-component/ABTestingComponent'
import DeepLinksComponent from '@/views/pages/links/DeepLinksComponent'
import TrafficRoutingRulesComponent from '@/views/pages/links/TrafficRoutingRulesComponent'
import PasswordProtectedComponent from '@/views/pages/links/PasswordProtectedComponent'

export default ({
  props: ['location'],
  data () {
    return {
      search_query: '',
      size: '14px',
      colorBlue: '#2d99ec',
      edit_slug: false,
      active_input: false,
      invalidPassword: false,
      invalidPasswordUrl: false,
      isPermission: false,

      invalidFavicon: false,
      oldSlug: '',
      slugValue: '',
      min: new Date(),
      isTrialUserObj: {},
      validations: quickLinksValidations,
      messages: quickLinksMessages,

      sectionsDisplayChecks: {
        showCustomMeta: false,
        showCustomFavicon: false,
        showLinkExpiry: false,
        showABTestingObj: false
      },
      customizeLinkPreviewValidations: customizeLinkPreviewValidations,
      customizeLinkPreviewMessages: customizeLinkPreviewMessages,
      deepLinksBrandsList: [],
      linkExpireAttributesValidations: linkExpireAttributesValidations,
      linkExpireAttributesMessages: linkExpireAttributesMessages,
      trafficRoutingIframeLoader: false,
      trafficRoutingCTAFailed: false,
      checkTrafficRoutingIframeErrorCount: 0,
      passwordProtectedValidations: passwordProtectedValidations,
      searchFiltersMessages: searchFiltersMessages,
      scrollLoader: false,
      previewCall: false
    }
  },
  components: {
    'apply-utm-link': ApplyUTMLink,
    'url': URL,
    'ab-testing-component': ABTestingComponent,
    CreateQuickLink,
    'deep-links-component': DeepLinksComponent,
    'traffic-routing-rules-component': TrafficRoutingRulesComponent,
    'password-protected-component': PasswordProtectedComponent
  },
  computed: {
    ...mapGetters([
      'getQuickLink',
      'getLinksLoaders',
      'getQuickLinkTag',
      'getLoaders',
      'getCampaignLoaders',
      'getCampaigns',
      'getComponentCampaignList',
      'getCampaignsList'
    ])
  },
  async created () {
    this.isTrialUserObj = this.isTrialUser(this.getSubscription)
    await this.fetchDomains()
    this.sectionsDisplayChecks.showCustomMeta = false
    this.sectionsDisplayChecks.showCustomFavicon = false
  },
  watch: {
    'getQuickLink.url' (value) {
      this.setUTMPreviousId('')
    },
    'getQuickLink.seo.title' (value) {
      if (value && value.length > 0) {
        this.customizeLinkPreviewValidations.title = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.titleLength = !this.maxLength(value, 500)
      }
    },
    'getQuickLink.seo.description' (value) {
      if (value && value.length > 0) {
        this.customizeLinkPreviewValidations.description = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(value, 500)
      }
    },
    'getQuickLink.seo.image' (value) {
      this.customizeLinkPreviewValidations.url = this.requiredCheck(value)
      this.customizeLinkPreviewValidations.urlLength = !this.maxLength(value, 500)
    },
    'getSaveLink.custom_favicon_url' (value) {
      this.invalidFavicon = value && value.length > 0 && !this.isValidURL(value)
    },
    'getQuickLink.call_to_action_id' (value) {
      if (value && value.trim().length > 0) {
        this.$set(this.validations, 'campaign', false)
      }
    },
    'getQuickLink.custom_favicon_url' (value) {
      this.invalidFavicon = value && value.length > 0 && !this.isValidURL(value)
    },
    'getQuickLink.link_expire_attributes.expiry' (value) {
      if (value) this.linkExpireAttributesValidations.expiry = this.requiredCheck(value)
    },
    'getQuickLink.link_expire_attributes.link_expiry_logo' (value) {
      if (this.isAgency && value) {
        this.linkExpireAttributesValidations.url = this.requiredCheck(value)
        this.linkExpireAttributesValidations.urlLength = !this.maxLength(value, 500)
      }
    }
  },
  methods: {
    ...mapActions([
      'storeQuickLink',
      'uploadCustomMetaImage',
      'uploadCustomFaviconImage',
      'verifyImageResolution',
      'fetchCampaignsList',
      'fetchTags',
      'fetchDomains',
      'uploadPasswordProtectedLogo',
      'uploadLinkExpiryLogo',
      'fetchCustomizeLinkPreviewObj',
      'showDeepLinksBrandsList',
      'setUTMPreviousId',
      'setComponentCampaignList'
    ]),

    passwordProtectedObj (event) {
      this.getQuickLink.password = event.password
      this.getQuickLink.password_protected_logo = event.password_protected_logo
      this.getQuickLink.password_protected = event.password_protected
    },
    async validateAndStoreQuickLink () {
      let validations = this.validations
      let quickLink = this.getQuickLink
      this.$set(validations, 'campaign', this.requiredCheck(quickLink.call_to_action_id))
      this.$set(validations, 'url', this.requiredCheck(quickLink.url))
      this.getQuickLink.url = this.urlConcatenation(this.getQuickLink.url)
      this.validations.urlValid = !this.isValidURL(this.getQuickLink.url)
      this.checkDeepLink(this.getQuickLink.url, this.deepLinksBrandsList)
      let result = Object.keys(validations).every(k => validations[k] === false)
      if (result) {
        let res = await this.storeQuickLink()
        if (res) {
          this.deepLinksBrandsList = await this.showDeepLinksBrandsList()
          if(!this.getQuickLink.iframe_allowed) {
            this.setCustomMeta()
            this.setCustomFavicon()
          }
          await this.fetchTags()
          this.$bvModal.show('quickLinkModal')
        }
      }
    },
    selectCampaignForQuickLink (campaign) {
      this.$store.commit(linkTypes.SET_QUICK_LINK_CAMPAIGN_ID, campaign._id)
    },
    closeQuickLinkModal () {
      this.$bvModal.hide('quickLinkModal')
      this.getQuickLink._id = null
      this.getQuickLink.call_to_action_id = null
      this.getQuickLink.shorten_url = null
      this.getQuickLink.shorten_url_lower_case = null
      this.getQuickLink.has_custom_message = false
      this.getQuickLink.url = ''
      this.getQuickLink.tags = []
      this.getQuickLink.message_headline = 'Your headline goes here'
      this.getQuickLink.message = 'Your message goes here'
      this.getQuickLink.duplicate = false
      this.getQuickLink.qr_code_url = null
      this.getQuickLink.note = null
      this.getQuickLink.cta = {
        domainId: null
      }
      this.invalidPassword = false
      this.invalidFavicon = false
      this.invalidPasswordUrl = false
      this.invalidExpiryDate = false
      this.getQuickLink.password = ''
      this.getQuickLink.password_protected_logo = null
      this.getQuickLink.password_protected = false
      this.getQuickLink.link_expire_attributes = {
        expiry: '',
        expiry_time: '00:00:00',
        expiry_datetime: '',
        link_expiry_logo: ''
      }
      this.getQuickLink.seo = {
        title: '',
        description: '',
        image: ''
      }
      this.getQuickLink.custom_favicon_url = ''
      this.edit_slug = false
      this.slugValue = null
      this.oldSlug = null
      this.sectionsDisplayChecks = {
        showCustomMeta: false,
        showCustomFavicon: false,
        showLinkExpiry: false
      }
      this.customizeLinkPreviewValidations = {
        title: false,
        titleLength: false,
        description: false,
        descriptionLength: false,
        url: false,
        urlLength: false,
        urlValid: false
      }
      this.getQuickLink.split_urls = []
      this.getQuickLink.is_ab_test = false
      this.getQuickLink.is_deep_link_enabled = false
      this.getQuickLink.is_deep_link_allowed = false
      this.getQuickLink.routing_rules = []
      this.getQuickLink.routing_rules_edit = false
      this.getQuickLink.is_routing_rule = false
      this.linkExpireAttributesValidations = {
        expiry: false,
        url: false,
        urlLength: false
      }
      this.invalidPasswordUrl = false
      this.getCampaigns.search = ''
    },

    // create quick link methods for editing slug
    editQuickLinkSlug () {
      this.edit_slug = true
      this.slugValue = this.getQuickLink.shorten_url
      if (!this.oldSlug) this.oldSlug = this.getQuickLink.shorten_url
      setTimeout(() => {
        this.$refs.editSlugField.focus()
      })
    },
    async editSlugForQuickLink () {
      this.edit_slug = false
      if (!this.slugValue && this.slugValue == '') {
        this.getQuickLink.shorten_url = this.oldSlug
        this.slugValue = this.oldSlug
        this.alertMessage('Slug value could not be empty', 'error')
        return
      }

      if (this.checkSlugLength(this.slugValue)) {
        this.alertMessage(slugMessages.slug_length, 'error')
        return
      }

      if (this.getQuickLink.shorten_url === this.slugValue) return

      this.getQuickLink.shorten_url = this.slugValue
      let res = await this.$store.dispatch('updateSlug', true)

      if (res.data.status) {
        this.$store.dispatch('toastNotification', { message: 'Your link slug has been updated' })
        this.oldSlug = this.getQuickLink.shorten_url
        this.slugValue = this.getQuickLink.shorten_url
        return
      }

      this.$store.dispatch('toastNotification', { message: res.data.message, type: 'error' })

      this.getQuickLink.shorten_url = this.oldSlug
      this.slugValue = this.oldSlug

    },
    close () {
      this.$parent.$bvModal.hide('quickLinkModal')
      this.$router.push({ name: 'link' })
    },

    setLinkExpiry () {
      this.sectionsDisplayChecks.showLinkExpiry = !this.sectionsDisplayChecks.showLinkExpiry
      if (this.sectionsDisplayChecks.showLinkExpiry) {
        this.getQuickLink.link_expire_attributes = {
          expiry: '',
          expiry_time: '00:00:00',
          expiry_datetime: '',
          link_expiry_logo: ''
        }
      }
    },
    async setCustomMeta () {
      if(!this.getQuickLink.iframe_allowed) {
        this.sectionsDisplayChecks.showCustomMeta = true
        return
      }

      this.sectionsDisplayChecks.showCustomMeta = !this.sectionsDisplayChecks.showCustomMeta
      this.getQuickLink.seo.title = ''
      this.getQuickLink.seo.description = ''
      this.getQuickLink.seo.image = ''

      if (this.sectionsDisplayChecks.showCustomMeta) {
        await this.setCustomizeLinkPreview()
      }
    },
    async setCustomizeLinkPreview () {
      let linkPreviewObj = await this.fetchCustomizeLinkPreviewObj({ 'url': this.getQuickLink.url })
      this.getQuickLink.seo.title = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.title : null
      this.getQuickLink.seo.description = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.description : null
      if (linkPreviewObj.data.facebook.image && (linkPreviewObj.data.facebook.image.includes('http://') || linkPreviewObj.data.facebook.image.includes('https://'))) {
        this.getQuickLink.seo.image = linkPreviewObj.data.facebook.image
      } else {
        this.getQuickLink.seo.image = linkPreviewObj.data.images[0]
      }
    },
    setCustomFavicon () {
      if(!this.getQuickLink.iframe_allowed) {
        this.sectionsDisplayChecks.showCustomFavicon = true
        return
      }
      this.sectionsDisplayChecks.showCustomFavicon = !this.sectionsDisplayChecks.showCustomFavicon
      this.getQuickLink.custom_favicon_url = ''
    },
    async update () {
      if (this.getQuickLink.password_protected && !this.isPasswordProtectionValid(this.passwordProtectedValidations, this.getQuickLink.password_protected, this.getQuickLink.password, this.getQuickLink.password_protected_logo)) {
        this.$store.dispatch('toastNotification', {
          message: 'Password Protection validation failed.',
          type: 'error'
        })
        return
      }

      if (this.sectionsDisplayChecks.showCustomFavicon && !this.isValidURL(this.getQuickLink.custom_favicon_url)) {
        this.invalidFavicon = true
        return
      }

      //customize link preview
      let customizeLinkPreviewValidate = false
      if (this.sectionsDisplayChecks.showCustomMeta) {
        this.customizeLinkPreviewValidations.title = this.requiredCheck(this.getQuickLink.seo.title)
        this.customizeLinkPreviewValidations.titleLength = !this.maxLength(this.getQuickLink.seo.title, 500)
        this.customizeLinkPreviewValidations.description = this.requiredCheck(this.getQuickLink.seo.description)
        this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(this.getQuickLink.seo.description, 500)
        this.customizeLinkPreviewValidations.url = this.requiredCheck(this.getQuickLink.seo.image)
        this.customizeLinkPreviewValidations.urlLength = !this.maxLength(this.getQuickLink.seo.image, 500)
        customizeLinkPreviewValidate = !Object.keys(this.customizeLinkPreviewValidations).every(k => this.customizeLinkPreviewValidations[k] == false)
      }
      if (customizeLinkPreviewValidate) return

      //link expiry
      let linkExpiryValidate = false
      if (this.sectionsDisplayChecks.showLinkExpiry) this.linkExpireAttributesValidations.expiry = this.requiredCheck(this.getQuickLink.link_expire_attributes.expiry)
      if (this.isAgency && this.sectionsDisplayChecks.showLinkExpiry) {
        this.linkExpireAttributesValidations.url = this.requiredCheck(this.getQuickLink.link_expire_attributes.link_expiry_logo)
        this.linkExpireAttributesValidations.urlLength = !this.maxLength(this.getQuickLink.link_expire_attributes.link_expiry_logo, 500)
      }
      linkExpiryValidate = !Object.keys(this.linkExpireAttributesValidations).every(k => this.linkExpireAttributesValidations[k] == false)
      if (linkExpiryValidate) return

      if (!this.sectionsDisplayChecks.showLinkExpiry) {
        this.getQuickLink.link_expire_attributes = {
          expiry: '',
          expiry_time: '00:00:00',
          expiry_datetime: '',
          link_expiry_logo: ''
        }
      }

      if (this.sectionsDisplayChecks.showLinkExpiry && this.getQuickLink.link_expire_attributes.expiry) {
        let d = new Date(this.getQuickLink.link_expire_attributes.expiry)
        d.setHours(this.getQuickLink.link_expire_attributes.expiry_time.substring(0, 2))
        d.setMinutes(this.getQuickLink.link_expire_attributes.expiry_time.substring(3, 5))
        this.getQuickLink.link_expire_attributes.expiry_datetime = d.toISOString()
      }

      if (this.getQuickLink.split_urls.length <= 1) {
        if (this.getQuickLink.is_ab_test) {
          this.$store.dispatch('toastNotification', {
            message: 'At least two links are required for A/B Testing.',
            type: 'error'
          })
          return
        }

        this.getQuickLink.split_urls = []
      }

      // add comment what we are doing here
      const validABValidation = this.getQuickLink.split_urls
        .every((item) => Object.keys(item.validations).every(k => item.validations[k] === false))

      if (this.getQuickLink.is_ab_test && !validABValidation) {
        this.$store.dispatch('toastNotification', { message: 'A/B Testing Validations failed.', type: 'error' })
        return
      }

      const isValidWeight = this.isValidWeight(this.getQuickLink.split_urls)
      if (this.getQuickLink.is_routing_rule) this.getQuickLink.is_routing_rule = !!this.getQuickLink.routing_rules.length
      if (this.getQuickLink.is_routing_rule && this.getQuickLink.routing_rules_edit) {
        this.trafficRoutingIframeLoader = true
        this.checkTrafficRoutingIframeErrorCount = 0
        if (this.isTypeCTA(this.getQuickLink.call_to_action_id)) {

          const uniqueThen = [...new Set(this.getQuickLink.routing_rules.map((item) => {return item.then}))]

          const promises = uniqueThen.map((item) => {
            return this.checkLinkPreview(item, this.getQuickLink.call_to_action_id)
          })

          const apiData = await Promise.all(promises)
          apiData.filter((item, index) => {
            if (item.data.status == false) {
              this.checkTrafficRoutingIframeErrorCount += this.getQuickLink.routing_rules.filter(item => item.then === uniqueThen[index]).length
            }
          })

          if (this.checkTrafficRoutingIframeErrorCount) {
            this.trafficRoutingIframeLoader = false
            this.trafficRoutingCTAFailed = true
            return
          }
        }
        this.trafficRoutingIframeLoader = false
        this.trafficRoutingCTAFailed = false

        const rules = this.getQuickLink.routing_rules.map(rule => {
          const blocks = rule.blocks.map(block => {
            return block.reduce((initialVal, currentVal) => {
              initialVal[currentVal.key] = currentVal.value.map(item => item.key)
              return initialVal
            }, {})
          })

          return {
            blocks,
            then: rule.then
          }
        })
        this.$store.dispatch('setTrafficRouting', { routing_rules: rules, isEdit: false })
      }

      if (this.getQuickLink.is_ab_test && !isValidWeight) {
        this.$store.dispatch('toastNotification', { message: 'A/B Testing Validations failed.', type: 'error' })
        return
      }

      if (this.getQuickLink.is_deep_link_enabled && !this.getQuickLink.is_deep_link_allowed) {
        this.$store.dispatch('toastNotification', { message: 'Deep Link Validation failed.', type: 'error' })
        return
      }

      await this.storeQuickLink()
    },
    showABTesting (event) {
      this.getQuickLink.is_ab_test = event
      this.sectionsDisplayChecks.showABTestingObj = event
    },
    showTrafficRoutingRules (event) {
      this.getQuickLink.is_routing_rule = event
    },
    copyUrlName () {
      let testingCodeToCopy = document.querySelector('#copy-url')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.onCopy()
      } catch (err) {
        this.onError()
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    showPreview (calledBy = '') {
      this.previewCall = calledBy
      this.$bvModal.show('seo-preview')
    },
    async uploadMetaImage (event) {
      await this.uploadCustomMetaImage(event)
      this.verifyImageResolution(this.getQuickLink.seo.image)
    }
  }
})
</script>
<style scoped lang="less">
.link_block {
  text-align: left;
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  background-color: #ffffff;
  padding: 14px 20px;
  border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  span {
    i {
      color: #007bff;
      font-weight: 100;
    }
  }
}

.link_block i {
  cursor: pointer;
}

.dashboard_component .shorten_form_block .shorten_form_block_inner {
  border: 0 !important;
}

.another_link {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.link_url {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editable_url {
  width: 50% !important;
  display: inline-block;
  height: 0;
  border: 0;
  padding: 10px 0px;

  &:focus {
    border: none !important;
  }
}

.btn---cta {
  margin-top: 28px !important;
}

.tag_list_dd {
  overflow-y: auto !important;
}

.data-picker, .time-picker {
  label {
    padding-top: 0.5rem !important;
    font-size: smaller !important;
    color: rgba(49, 58, 81, 0.6) !important;
  }

  button {
    svg {
      font-size: 110% !important;
    }
  }
}
</style>
<style lang="less">
.customDomainModal {
  .modal-dialog {
    .modal-content {
      background: #f2f6f7;
    }
  }
}
</style>

<template>

    <div  ref="confirmationBox"  data-backdrop="static" data-keyboard="false"  class="popupAnimation modal normal_modal alertBox" :id="id"
         tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
        <div class="connect-modal w500" >
            <div class="content-modal">
                <div class="modal_inner clear basic_form ">

                    <div v-if="getModalStatus == 'success'" class="svg_animation_success">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke- miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>

                    </div>

                    <div v-else-if="getModalStatus == 'fail'" class="svg_animation_success">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                        </svg>
                    </div>

                    <div v-else class=" col-sm-12 alert_content">

                        <slot name="header"></slot>

                        <slot name="description"></slot>

                        <slot name="alert_list"></slot>

                        <slot name="footer"></slot>

                    </div>

                    <div class="col-sm-12 footer_box" v-if="getModalStatus == ''">
                        <button data-dismiss="modal" class="btn  cancel_btn" >{{cancelText}}</button>
                        <button :class="greenColor"  class="btn confirm_btn" @click="confirmClick" >{{confirmText}}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { mapGetters } from 'vuex'

export default {

  props: {

    'id': {
      default: 'confirmationBox'
    },

    'confirmText': {
      default: 'Process'
    },
    'greenColor': {
      default: ''
    },
    'cancelText': {
      default: 'Cancel'
    },

    'confirmCallback': {
      type: Function
    }

  },
  data () {
    return {

    }
  },

  computed: {

    ...mapGetters([

      'getModalStatus'

    ])

  },

  methods: {

    confirmClick () {
      this.confirmCallback()
    }

  }
}
</script>

<template>
  <div class="overview_block pt-4 inline_boxes d-flex align-items-start">
    <div class="white_box pr-3">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Overview</h2>
          </div>

        </div>
        <div class="box_content">
          <template v-if="loader">
            <clip-loader :color="color" :size="size"></clip-loader>
          </template>
          <template v-else>

            <div class="inline_boxes  d-flex align-items-center">
              <div class="box_icon pr-2 pb-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Brands</p>
                    <h3>{{ totalBrands }}</h3>
                    <p class="small_text">Total brands</p>
                  </div>
                </div>
              </div>
              <div class="box_icon pr-2 pb-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Campaigns</p>
                    <h3>{{ totalCampaigns }}</h3>
                    <p class="small_text">Total campaigns</p>
                  </div>
                </div>
              </div>
              <div class="box_icon  pb-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Links</p>
                    <h3>{{ commaSeparatedNumber(totalLinks) }}</h3>
                    <p class="small_text">Total Links</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="inline_boxes d-flex align-items-center">
              <div class="box_icon pr-2 pt-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Clicks</p>
                    <h3>{{ commaSeparatedNumber(totalClicks) }}</h3>
                    <p class="small_text">From {{ commaSeparatedNumber(totalLinks) }} Links</p>
                  </div>
                </div>
              </div>
              <div class="box_icon pr-2 pt-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Unique Clicks</p>
                    <h3>{{ commaSeparatedNumber(uniqueClicks) }}</h3>
                    <p class="small_text">From {{ commaSeparatedNumber(totalClicks) }} Clicks</p>
                  </div>
                </div>
              </div>
              <div class="box_icon pt-2">
                <div class="box_icon_inner">
                  <div class="box_left">
                    <p class="top_text">Conversions</p>
                    <h3>{{ commaSeparatedNumber(totalConversions) }}</h3>
                    <p class="small_text">From {{ commaSeparatedNumber(totalClicks) }} Clicks</p>
                  </div>
                </div>
              </div>
            </div>

          </template>

        </div>
      </div>
    </div>
    <div class="white_box pl-3">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Overall Clicks</h2>
          </div>

        </div>
        <div class="box_content">

          <template v-if="loader == false">
            <div class="inline_boxes d-flex align-items-center">
              <div class="box_icon pr-2 pb-2">
                <div class="box_icon_inner d-flex align-items-center">
                  <div class="box_left">
                    <h3>{{ commaSeparatedNumber(clicksHistory.today.doc_count) }} Clicks</h3>
                    <p>Today</p>
                  </div>
                  <div class="right ml-auto">
                    <i class="fal fa-calendar-day"></i>
                  </div>
                </div>
              </div>
              <div class="box_icon  pb-2">
                <div class="box_icon_inner d-flex align-items-center">
                  <div class="box_left">
                    <h3>{{ commaSeparatedNumber(clicksHistory.yesterday.doc_count) }} Clicks</h3>
                    <p>Yesterday</p>
                  </div>
                  <div class="right ml-auto">
                    <i class="fal fa-calendar-week"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="inline_boxes d-flex align-items-center">
              <div class="box_icon pr-2 pt-2">
                <div class="box_icon_inner d-flex align-items-center">
                  <div class="box_left">
                    <h3>{{ commaSeparatedNumber(clicksHistory.this_month.doc_count) }} Clicks</h3>
                    <p>This Month</p>
                  </div>
                  <div class="right ml-auto">
                    <i class="fal fa-calendar-alt"></i>
                  </div>
                </div>
              </div>
              <div class="box_icon   pt-2">
                <div class="box_icon_inner d-flex align-items-center">
                  <div class="box_left">
                    <h3>{{ commaSeparatedNumber(clicksHistory.last_month.doc_count) }} Clicks</h3>
                    <p>Last Month</p>
                  </div>
                  <div class="right ml-auto">
                    <i class="fal fa-calendar-alt"></i>
                  </div>
                </div>
              </div>
            </div>

          </template>
          <template v-else>
            <clip-loader :color="color" :size="size"></clip-loader>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DARK_PURPLE_LOADER_COLOR } from '../../../common/constants'

export default ({
  name: 'overview-statistics',
  props: {
    stats: {},
    statistics: {},
    loader: {
      default: false
    }
  },
  data () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR
      // size1: '14px',
      // color1: '#fff',
    }
  },
  computed: {
    totalClicks () {
      return this.statistics.hits.total
    },
    totalConversions () {
      return this.statistics.aggregations.clicked_conversions.doc_count
    },
    uniqueClicks () {
      return this.statistics.aggregations.unique_clicks.value
    },
    clicksHistory () {
      return this.statistics.aggregations.clicks_history.buckets
    },
    totalBrands () {
      return this.statistics.account_statistics.brands
    },
    totalCampaigns () {
      return this.statistics.account_statistics.campaigns
    },
    totalLinks () {
      return this.statistics.account_statistics.links
    }
  }

})
</script>
<style scoped lang="less">
.box_icon .box_icon_inner p {
  font-weight: 400;
}
.box_icon .box_icon_inner h3 {
  font-weight: 600;
}
</style>

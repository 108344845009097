<template>

  <div class="dashboard_component" v-if="getProfile._id">
    <apply-utm-modal></apply-utm-modal>
    <transition name="slide">
      <!-- show the loader while the data is being loaded -->
      <!--            <div class="container" > &lt;!&ndash; v-if="$parent.platform_loading" &ndash;&gt;-->
      <!--                <div class="dashboard_component_inner">-->
      <!--                  <clip-loader v-if="$parent.platform_loading" :color="'#0095f3'" :size="size"></clip-loader>-->
      <!--                </div>-->
      <!--            </div>-->

      <!-- the actual data will be showing over here -->
      <div class="container">

        <guided-tour v-if="getGuidedTour.step1 && !getSiteDetails.isWhiteLabel"></guided-tour>
        <guided-tour v-if="getGuidedTour.step4 && !getSiteDetails.isWhiteLabel"></guided-tour>
        <template v-if="!getSiteDetails.isWhiteLabel && getProfile.username && getProfile.onboarding === false">
          <initial-onboarding-view></initial-onboarding-view>
        </template>
        <template v-else>
          <div class="dashboard_component_inner">
            <!--//old box style-->
            <onboarding-getting-started-steps v-if="!getSiteDetails.isWhiteLabel"></onboarding-getting-started-steps>
            <!--v-if="hideBoxStatus"-->
            <!--//for chrome extension-->

            <chrome-extension-notification v-if="getProfile && getProfile.triggers && getProfile.policy && getProfile.policy.can_add_links && !getSiteDetails.isWhiteLabel"></chrome-extension-notification>

            <div class="w-100 flex justify-content-between align-items-center">
              <div class="w-100 quick_link_section pr-3" v-if="getProfile && getProfile.policy && getProfile.policy.can_add_links">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Create Quick {{ getSiteDetails.agency_name }} Link</h2>
                      </div>
                      <div class="right ml-auto">
                        <!--<quick-link-action location="top"></quick-link-action>-->

                      </div>
                    </div>
                    <div class="box_content">
                      <quick-link-action/>
                      <template>

                        <!--                                            <create-quick-link location="dashboard_shortner_form"></create-quick-link>-->
                      </template>

                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 pl-3">
                <RecentLinks />
              </div>
            </div>

            <overview-statistics :stats="stats" :statistics="overview"
                                 :loader="loader"></overview-statistics>

            <!--//chart block-->
            <div class="w-100 flex justify-content-between align-items-center">
              <div class="click_performance w-100 pt-4 pr-3">
                <div class="white_box w-100">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Clicks Performance</h2>
                      </div>
                      <!--<div class="right ml-auto">
                          <b-dropdown ref="timeLineDropdown" right
                                      class="ml-2  dropdown-menu-right default_style_dropdown"
                                      :no-caret="true">
                              &lt;!&ndash;d-flex align-items-center&ndash;&gt;
                              <div class="text-center dropdown_header  rounded_shape  d-flex align-items-center"
                                   slot="button-content">
                                  <div class="text">
                                      <p class="text-capitalize">{{dropdownValue}}</p>
                                  </div>
                                  <span class="arrow_icon ml-auto">
                              <i class=" fal fa-angle-down ml-3"></i>
                          </span>
                              </div>
                              <ul class="clear inner">
                                  <li @click="dropdownValue = 'Daily',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-day mr-2"></i> Daily
                                  </li>
                                  <li @click="dropdownValue = 'Weekly',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-week mr-2"></i> Weekly
                                  </li>
                                  <li @click="dropdownValue = 'Monthly',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-alt mr-2"></i> Monthly
                                  </li>
                                  <li @click="dropdownValue = 'Last 6 Months',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-alt mr-2"></i>Last 6 Months
                                  </li>
                                  <li @click="dropdownValue = 'All Time',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-check mr-2"></i>All Time
                                  </li>


                              </ul>
                          </b-dropdown>
                      </div>-->

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>
                      <template v-else-if="this.activity.clicks">
                        <highcharts :options="clicksChart" ref="highcharts"></highcharts>
                      </template>
                      <template v-else>
                        <no-events-available></no-events-available>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="conversions_performance w-100 pl-3 pt-4">
                <div class="white_box w-100">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Conversions Performance</h2>
                      </div>

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>
                      <template v-else-if="this.activity.conversions">
                        <highcharts :options="conversionsChart" ref="highcharts"></highcharts>
                      </template>
                      <template v-else>
                        <no-events-available></no-events-available>

                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- We need to remove this if condition in future -->
            <!-- filter bar chart -->
            <simple-bar-chart :paginationChunk="10" :loading="simpleBarLoader" :stats="referralStats" class="pr-3"
                              :title="'Referrals'"></simple-bar-chart>

            <!-- simple bar chart -->
            <div class="d-flex align-items-start">
              <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="deviceTypeStats" class="pr-3"
                                :title="'Device OS'"></simple-bar-chart>
              <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="browserStats" class="pr-3"
                                :title="'Browser'"></simple-bar-chart>
              <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="countryStats"
                                :title="'Country'"></simple-bar-chart>
            </div>


            <div v-if="false" class="d-flex align-items-start">
              <div class="performance_charts visitor_refferal_chart pr-3 pt-4">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Countries</h2>
                      </div>

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>

                      <template v-else>
                        <template v-if="countries_item > 0">
                          <highcharts :options="pieDataCountry"
                                      ref="countryChart"></highcharts>
                        </template>
                        <template v-else>
                          <no-events-available></no-events-available>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="false" class="performance_charts conversions_performance pl-3 pt-4">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Visitor Referral</h2>
                      </div>

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>

                      <template v-else>
                        <template v-if="referral_item > 0">
                          <highcharts :options="pieDataReferral"
                                      ref="referralChart"></highcharts>
                        </template>
                        <template v-else>
                          <no-events-available></no-events-available>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="conversions_performance pt-4" v-if="showDetailView">
              <div class="white_box ">
                <div class="box_inner">
                  <div class="box_head d-flex align-items-center">
                    <div class="left">
                      <h2>Recent Activity</h2>
                    </div>

                  </div>
                  <div class="box_content">
                    <template>
                      <detail-table-list></detail-table-list>
                    </template>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </template>
      </div>
    </transition>
  </div>

</template>

<script>
import { statisticsURL, userActivityURL } from '../../config/config.js'
import Header from '@/components/Header.vue'
import OverviewStatistics from './dashboard/OverviewStatistics'
import ChromeExtensionNotification from './onboarding/ChromeExtensionNotification'
import Sidebar from '@/components/Sidebar.vue'
import DetailTableList from '@/views/pages/analytics/detail-view/tables/DetailTableList'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapActions, mapGetters } from 'vuex'

import GuidedTour from '../guided-tour/GuidedTour.vue'

import Dropdown from '@/ui/Dropdown.vue'
import ApplyUTMLink from '@/views/pages/setting/utm/ApplyUTMLink'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import CreateQuickLink from '@/views/pages/links/CreateQuickLink'
import QuickLinkAction from '@/views/pages/links/QuickLinkAction'
import { linkTypes } from '@/state/modules/mutation-types'
import {
  DARK_PURPLE_LOADER_COLOR,
  overviewOfClicksPerformance,
  overviewOfConversionsPerformance,
  pieVisitedCountriesChart,
  pieVisitedFromChart
} from '../../common/constants'
import { JWT_CHROME_LOCAL_STORAGE } from '@/constants'
import ApplyUTMModal from '@/views/pages/setting/utm/ApplyUTMModal'
import SimpleBarChart from '@/components/custom-bar-charts/SimpleBarChart'
import FilterBarChart from '@/components/custom-bar-charts/FilterBarChart'

const InitialOnboardingView = () => import('./onboarding/InitialOnboardingView')
const OnboardingGettingStartedSteps = () => import('./onboarding/OnboardingGettingStartedSteps')
// import DnsModal from '../ui/DnsModal.vue'

export default {
  components: {
    Sidebar,
    'header-bar': Header,
    GuidedTour,
    ClipLoader,
    Dropdown,
    ConfirmationBox,
    'apply-utm-link': ApplyUTMLink,
    CreateQuickLink,
    QuickLinkAction,
    InitialOnboardingView,
    OnboardingGettingStartedSteps,
    ChromeExtensionNotification,
    OverviewStatistics,
    DetailTableList,
    'apply-utm-modal': ApplyUTMModal,
    // DnsModal
    'simple-bar-chart': SimpleBarChart,
    'filter-bar-chart': FilterBarChart,
    RecentLinks: () => import('./dashboard/RecentLinks')
  },
  async created () {
    if (this.$route.query && this.$route.query.token) {
      localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, this.$route.query.token)
    }

    this.$store.commit(linkTypes.SET_QUICK_LINK_DEFAULT_VALUE)
    await this.performance()
  },

  data: function () {
    return {
      activity: {
        clicks: true,
        conversions: true
      },
      dropdownValue: 'Daily',
      overview: {},
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      size1: '14px',
      color1: '#fff',
      loader: true,
      active_input: false,
      stats: {
        brands: 0,
        links: 0,
        visitors: 0,
        clicks: 0,
        unique_visitors: 0,
        cta: 0,
        pixels: 0,
        custom_domains: 0,
        last_month: 0,
        this_month: 0,
        yesterday: 0,
        today: 0
      },
      countries_item: 0,
      referral_item: 0,

      pieDataReferral: pieVisitedFromChart(),
      pieDataCountry: pieVisitedCountriesChart(),
      clicksChart: overviewOfClicksPerformance(),
      conversionsChart: overviewOfConversionsPerformance(),
      showDetailView: false,
      countryStats: [],
      browserStats: [],
      deviceTypeStats: [],
      referralStats: [],
      simpleBarLoader: true
    }
  },
  computed: {
    ...mapGetters(['getSubscription', 'getGuidedTour', 'getProfile', 'getQuickLink'])
  },
  mounted () {
    window.addEventListener('scroll', this.scroll)
  },
  methods: {
    ...mapActions(['setAllCallToActionsFetchStatus']),
    async performance () {
      // displaying loaders
      this.loader = true
      this.simpleBarLoader = true

      let res = await this.$store.dispatch('showStatistics')
      this.overview = (res.data.status) ? res.data.data : {}
      // prepare the payload for the charts
      if (res.data.data) {
        let performanceHistory = res.data.data.aggregations.performance_history.buckets
        this.countryStats = await this.processBarChartsStats(res.data.data.aggregations.countries.buckets, true)
        this.browserStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_browsers.buckets)
        this.deviceTypeStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_os.buckets, false, 'fetchOSIcon')
        this.referralStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_domains.buckets)
        this.performanceChart(performanceHistory, this.activity, this.conversionsChart, this.clicksChart)

        if (res.data.data.aggregations) {
          let aggregations = res.data.data.aggregations
          this.countriesChart(aggregations)
          this.visitedFromDomainsChart(aggregations)
        }
      }

      // disabling loaderss
      this.loader = false
      this.simpleBarLoader = false
    },
    visitedFromDomainsChart (aggregations) {
      let visitedFromDomains = []

      aggregations.visited_from_domains.buckets.forEach(item => {
        visitedFromDomains.push([item.key, item.doc_count])

      })
      this.referral_item = visitedFromDomains.length

      this.pieDataReferral.series =
        [{
          name: 'Visits',
          data: visitedFromDomains
        }]

    },
    countriesChart (aggregations) {
      let countries = []

      aggregations.countries.buckets.forEach(item => {
        countries.push([item.key, item.doc_count])

      })
      this.countries_item = countries.length
      this.pieDataCountry.series =
        [{
          name: 'Country',
          data: countries
        }]
    },
    statistics () {
      this.$http.post(statisticsURL).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.stats = response.data.stats
              let clicks = []
              let conversions = []
              response.data.performance.forEach(function (element) {
                clicks.push([Date.parse(element.key_as_string), element.doc_count])
                conversions.push([Date.parse(element.key_as_string), element.click_true.doc_count])
              })
              let countries_chart = []
              response.data.visitorCountries.forEach(function (element) {
                countries_chart.push([element.key, element.doc_count])
              })
              this.countries_item = countries_chart.length
              let visitorReferral = []
              response.data.visitorReferral.forEach(function (element) {
                visitorReferral.push([element.key, element.doc_count])
              })
              this.referral_item = visitorReferral.length

              this.pieDataReferral.series =
                [{
                  name: 'Visits',
                  data: visitorReferral
                }]
              this.pieDataCountry.series =
                [{
                  name: 'Visits',
                  data: countries_chart
                }]

              this.clicksChart.series = [{
                name: 'Clicks',
                data: clicks,
                color: '#d2e3fd',
                shadow: {
                  width: 0
                }
              }]
              this.conversionsChart.series = [{
                name: 'Conversions',
                data: conversions,
                color: '#cdeac4',
                shadow: {
                  width: 0
                }
              }]

              // passing data to gosquared.
              try {
                _gs('identify', {
                  id: this.getProfile._id,
                  custom: {
                    brands: this.stats.brands,
                    campaigns: this.stats.cta,
                    links: this.stats.links,
                    clicks: this.stats.visitors,
                    conversions: this.stats.clicks,
                    pixels: this.stats.pixels,
                    custom_domains: this.stats.custom_domains

                  }
                })
              } catch (m) {
                console.log(m)
              }
              // customer io.
              try {
                // _cio.identify({
                //   id: this.getProfile._id,
                //   brands: this.stats.brands,
                //   campaigns: this.stats.cta,
                //   links: this.stats.links,
                //   clicks: this.stats.visitors,
                //   conversions: this.stats.clicks,
                //   pixels: this.stats.pixels,
                //   custom_domains: this.stats.custom_domains
                // })
              } catch (e) {

              }

            } else {
              this.alertMessage(response.data.message, 'error')
            }
          }
          this.loader = false
        },
        response => {
          this.loader = false
        }
      )
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          this.showDetailView = true
        }
      }
    }
  }

}
</script>

<template>
  <div class="shorten_form_block text-center" :class="location" v-if="getQuickLink._id">
    <!--        <img class="close_box" src="/img/cancel.png"-->
    <!--             @click.prevent="$store.commit('SET_QUICK_LINK_DEFAULT_VALUE')" alt="">-->
    <div class="shorten_form_block_inner ">

      <div class="mt-3 mb-3 share_link_block d-flex align-items-center clear">
        <div class="link_block d-inline-flex" :class="{'active': active_input}">

          <span v-if="getSiteDetails.isWhiteLabel && getSiteDetails.custom_domain_name">{{
              getSiteDetails.custom_domain_name
            }}</span>
          <span v-else>{{ getQuickLink.domain.url }}</span>

          <span v-if="!edit_slug" class="text link_url">{{ getQuickLink.shorten_url }}</span>

          <input v-else @focus="active_input = true"
                 style="width: 150px"
                 @blur="active_input = false" class="editable_url"
                 type="text" v-model="getQuickLink.shorten_url"
          >
          <input type="hidden" id="copy-url"
                 :value="copyUrl(getQuickLink.shorten_url,getQuickLink.domain.url)">
          <span style="margin-left: auto;">
                        <i style="float: right; margin-top: 5px;" v-if="!edit_slug" class="fa fa-files-o"
                           @click.stop.prevent="copyUrlName"></i>
                        <i style="margin-top: 5px; margin-right: 10px" v-if="!edit_slug && !getSiteDetails.isWhiteLabel"
                           @click.prevent="editQuickLinkSlug()" class="fa fa-pencil"></i>
                        <i style="margin-top: 5px; margin-right: 10px" v-if="edit_slug"
                           @click="editSlugForQuickLink()" class="fa fa-check"></i>
                        <i style="margin-top: 5px;" v-if="edit_slug" @click="edit_slug = false"
                           class="fa fa-times"></i>

                    </span>
        </div>
      </div>

      <div class="mt-3 mb-3 input_icon_text d-flex align-items-center profile__box tags_dd">

        <div class="avatar">
          <i class="fal fa-tag"></i>
        </div>

        <div class="content">
          <div class="url">Add tags (optional)</div>
          <div class="name">
            <input maxlength="50"
                   v-on:keyup="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag.trim())"
                   @focus="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag.trim())"
                   @blur="blurFocusQuickTag()"
                   type="text"
                   v-model="getQuickLinkTag.tag"
                   placeholder="Enter tag name...">
            <div class="tag_list_dd"
                 v-if="getQuickLinkTag.tag.length > 0 || getQuickLinkTag.focus === true">
              <ul class=""
                  v-if="getQuickLinkTag.tag.length > 0 && checkTagNotExist(getQuickLinkTag.tag,getQuickLink.tags)">
                <template
                  v-if="getLinks.suggestedTags.length > 0">
                  <li class="old_item"
                      v-for="tag in getLinks.suggestedTags">
                    <span
                      @click.prevent="addLinkQuickTag($event,getQuickLink._id,getTagName(tag._id),true)"
                      class="text">{{
                        getTagName(tag._id)
                      }}</span>
                  </li>
                </template>
                <li v-else class=" new_item">
                  <span
                    @click.prevent="addLinkQuickTag($event,getQuickLink._id,getQuickLinkTag.tag,true)"
                    class="text">Add new tag "{{
                      getQuickLinkTag.tag
                    }}"</span>
                </li>
              </ul>
              <ul class=""
                  v-else-if="getQuickLinkTag.tag.length === 0 && getQuickLinkTag.focus === true && getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
                <li class="old_item"
                    v-for="tag in getLinks.suggestedTags">
                                    <span class="text"
                                          @click.prevent="addLinkQuickTag($event,getQuickLink._id,getTagName(tag._id),true)">
                                        {{ getTagName(tag._id) }}
                                    </span>
                </li>
              </ul>

            </div>

          </div>

        </div>

      </div>

      <div class="text-center mt-3 tag-item-list">
        <ul>
          <!--          <template v-if="getLinksLoaders.quick_link">-->
          <!--            <clip-loader :color="'#168eea'"-->
          <!--                         :size="'#14px'"></clip-loader>-->
          <!--          </template>-->
          <template v-if="!getLinksLoaders.quick_link">
            <li v-for="(tag,tag_index) in getQuickLink.tags">
              <span class="text">{{ getTagName(tag) }}</span>
              <span class="cross_icon"
                    @click.prevent="removeLinkTag(getQuickLink._id,tag_index,tag,true)"></span>
            </li>
          </template>

          <li class="add_tag d-none">
            <span class="cross_icon add_icon"></span>
            <span class="text">add</span>

          </li>
        </ul>
      </div>

      <div class="d-flex mt-3 mb-3">
        <div class="col-md-12 col-lg-12 input_icon_text" style="display: inline-table">
          <div class="d-flex justify-content-between setting_item mb-3">
            <div class="text_sec">
              <p>Customize Link Preview</p>
            </div>
            <div class="status_sec" style="float: right">
              <label class="switch">
                <input type="checkbox" @click="setCustomMeta()">
                <div class="slider round"></div>
              </label>
            </div>
          </div>

          <!--meta title-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomMeta">
            <div class="content">
              <div class="url">
                Title
              </div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Enter the meta title"
                    name="link_seo_title"
                    @change="showUpdateButton()"
                    @keydown="showUpdateButton()"
                    @keyup="showUpdateButton()"
                    v-model="getQuickLink.seo.title" type="text"
                    class="form-control txt-left-left">
                </div>
              </div>
            </div>
          </div>

          <!--meta description-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomMeta">
            <div class="content">
              <div class="url">
                Description
              </div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Enter the meta description"
                    name="link_seo_title"
                    @change="showUpdateButton()"
                    @keydown="showUpdateButton()"
                    @keyup="showUpdateButton()"
                    v-model="getQuickLink.seo.description" type="text"
                    class="form-control txt-left-left">
                </div>
              </div>
            </div>
          </div>

          <!--meta image-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomMeta">
            <div class="content">
              <div class="url">
                Image URL
              </div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Enter the meta image URL"
                    name="link_seo_title"
                    v-model="getQuickLink.seo.image" type="text"
                    class="form-control txt-left-left">
                </div>
              </div>
            </div>
          </div>

          <!--upload meta image and preview-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomMeta">
            <div class="content">
              <div class="name">
                <div class="validation_input">
                  <input
                    id="custom_meta_image"
                    placeholder="Enter the meta image url"
                    type="file"
                    @change="uploadCustomMetaImage($event)"
                    accept=".png, .jpg, .jpeg, .gif"
                    class="form-control txt-left-left d-none">
                  <label for="custom_meta_image" class="upload-meta-image">
                    <i class="fa fa-upload" v-if="!getLoaders.custom_meta_image"
                       aria-hidden="true"></i>
                    <clip-loader v-if="getLoaders.custom_meta_image" class="loader" :size="size"
                                 :color="colorBlue"></clip-loader>
                    <span>Upload Image</span>
                  </label>
                  <div class="upload-meta-preview d-inline-block"
                       @click="$bvModal.show('seo-preview')">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                    <span>Preview</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <!--      custom favicon-->
      <div class="d-flex mt-3 mb-3">
        <div class="col-md-12 col-lg-12 input_icon_text" style="display: inline-table">
          <div class="d-flex justify-content-between setting_item mb-3">
            <div class="text_sec">
              <p>Customize Favicon</p>
            </div>
            <div class="status_sec" style="float: right">
              <label class="switch">
                <input type="checkbox" @click="setCustomMeta()">
                <div class="slider round"></div>
              </label>
            </div>
          </div>

          <!--meta image-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomFavicon">
            <div class="content">
              <div class="url">
                Custom Favicon URL
              </div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Enter the favicon image URL (16 x 16 recommended)"
                    name="link_seo_title"
                    v-model="getQuickLink.favicon_url" type="text"
                    class="form-control txt-left-left">
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="showCustomFavicon && invalidFavicon">
                            Enter the valid url.
            </span>

          <!--upload meta image and preview-->
          <div class="d-flex align-items-center url_input_main "
               v-if="showCustomFavicon">
            <div class="content">
              <div class="name">
                <div class="validation_input">
                  <input
                    id="custom_favicon_image"
                    placeholder="Enter the favicon image url (16 x 16 recommended)"
                    type="file"
                    @change="uploadCustomFaviconImage($event)"
                    accept=".png, .jpg, .jpeg, .gif, .ico"
                    class="form-control txt-left-left d-none">
                  <label for="custom_favicon_image" class="upload-meta-image">
                    <i class="fa fa-upload" v-if="!getLoaders.custom_favicon_image_url"
                       aria-hidden="true"></i>
                    <clip-loader v-if="getLoaders.custom_favicon_image_url" class="loader" :size="size"
                                 :color="colorBlue"></clip-loader>
                    <span>Upload Image</span>
                  </label>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="d-flex mt-3 mb-3">
        <div class="col-md-5 col-lg-5 input_icon_text" style="display: inline-table">
          <div class="d-flex justify-content-between setting_item mb-3">
            <div class="text_sec">
              <p>Password Protected</p>
            </div>
            <div class="status_sec" style="float: right">
              <label class="switch">
                <input type="checkbox" v-model="getQuickLink.password_protected"
                >
                <div class="slider round"></div>
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center url_input_main "
               v-if="getQuickLink.password_protected">

            <transition name="fade">
              <div class="content">
                <div class="url">
                  Password
                </div>
                <div class="name">
                  <div class="validation_input">
                    <input
                      placeholder="Enter your password"
                      name="link_password"
                      v-model="getQuickLink.password" type="password"
                      @change="showUpdateButton()"
                      @keydown="showUpdateButton()"
                      @keyup="showUpdateButton()"
                      class="form-control txt-left-left">
                  </div>
                </div>

              </div>
            </transition>

          </div>
          <span class="input-error" v-if="invalidPassword">
                        Password must contains at least 4 characters.
                    </span>
        </div>
        <div class="col-md-2 offset"></div>
        <div class="col-md-5 col-lg-5 input_icon_text" style="display: inline-table">
          <div class="d-flex justify-content-between setting_item mb-3">
            <div class="text_sec">
              <p>Link Expiry</p>
            </div>
            <div class="status_sec" style="float: right">
              <label class="switch">
                <input type="checkbox" @click="setLinkExpiry()">
                <div class="slider round"></div>
              </label>
            </div>
          </div>

          <div class="d-flex align-items-center url_input_main "
               v-if="showLinkExpiry">

            <div class="content">
              <div class="url">
                Date
              </div>
              <div class="name">
                <div class="validation_input">
                  <input style="padding-right: 30px;"
                         placeholder="Enter the date"
                         v-model="getQuickLink.link_expire_attributes.expiry" type="date"
                         @change="showUpdateButton()"
                         @keydown="showUpdateButton()"
                         @keyup="showUpdateButton()"
                         class="form-control txt-left-left">
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="d-flex mt-3 mb-3">
        <div class="col-md-12 col-lg-12 text-center" v-if="isUpdate">
          <transition name="fade">
            <button @click="update()"
                    class="mt-3 btn---cta btn-blue with-shadow btn-round  btn-bold">
              <span>Update</span>
              <span class="icon_right">
                <clip-loader v-if="getLinksLoaders.quick_link"
                             style="margin-top: 4px;"
                             :color="'#2d99ec'"
                             :size="'14px'"></clip-loader>
              </span>
            </button>
          </transition>

        </div>
      </div>

      <div class="mt-3 mb-3   tag-item-list " v-if="false">

        <ul>
          <template v-if="getLinksLoaders.quick_link">
            <clip-loader :color="'#168eea'"
                         :size="'#14px'"></clip-loader>
          </template>
          <template v-else>
            <li v-for="(tag,tag_index) in getQuickLink.tags">
              <span class="text">{{ getTagName(tag) }}</span>
              <span class="cross_icon"
                    @click.prevent="removeLinkTag(getQuickLink._id,tag_index,tag,true)"></span>
            </li>
          </template>

          <li class="add_tag d-none">
            <span class="cross_icon add_icon"></span>
            <span class="text">add</span>

          </li>
        </ul>
      </div>
      <div class="social-networks text-center">
        <p>
          Now go ahead and share this optimized {{ getSiteDetails.agency_name }}
          link with your audience on social, email, web or any other
          channel you want.
        </p>
        <social-sharing class="circle_social"
                        :url="socialUrl(getQuickLink.shorten_url,getQuickLink.domain.url)"
                        inline-template>
          <div>
            <network network="facebook">
              <i class="fab fa-fw fa-facebook fb_bg"></i>
            </network>
            <network network="twitter">
              <i class="fab fa-fw fa-twitter tw_bg"></i>
            </network>
            <network network="googleplus">
              <i class="fab fa-fw fa-google-plus gplus_bg"></i>
            </network>
            <network network="linkedin">
              <i class="fab fa-fw fa-linkedin lin_bg"></i>
            </network>
            <network network="pinterest">
              <i class="fab fa-fw fa-pinterest pin_bg"></i>
            </network>
            <network network="reddit">
              <i class="fab fa-fw fa-reddit reddit_bg"></i>
            </network>
          </div>
        </social-sharing>
      </div>

      <div class="text-center mt-3">

        <a class="another_link" @click.prevent="close()">Create
          Another {{ getSiteDetails.agency_name }} Link</a>
        <!--                <button @click.prevent="$store.commit('SET_QUICK_LINK_DEFAULT_VALUE')"-->
        <!--                        class="mt-3 btn-&#45;&#45;cta btn-blue with-shadow btn-round  btn-bold">-->
        <!--                    <span>Create Another Link</span>-->
        <!--                    <span class="icon_right">-->
        <!--                       <i-->
        <!--                               class="circle_icon d-flex align-items-center justify-content-center  fal fa-arrow-right  "></i>-->
        <!--                    </span>-->
        <!--                </button>-->

      </div>
    </div>
    <b-modal modal-class="modal_basic modal_basic_seo_preview modal-seo-preview" id="seo-preview" hide-footer
             hide-header>

      <div class="heading">
        <h3 class="d-inline-block title">
          <template>
            Preview
          </template>
        </h3>
        <img @click="$bvModal.hide('seo-preview')" class="close_modal" src="/assets/img/cross.svg" alt="">
      </div>
      <div class="modal_content">
        <img src="/img/notfound.png"
             v-if="!getQuickLink.seo || !getQuickLink.seo.image || getQuickLink.seo.image == ''">
        <img :src="getQuickLink.seo && getQuickLink.seo.image" v-else>
        <div class="heading">
          <div class="title" v-if="getQuickLink.seo && getQuickLink.seo.title == ''">Meta Title</div>
          <div class="title" v-else-if="getQuickLink.seo">{{ getQuickLink.seo.title }}</div>
          <div class="description" v-if="getQuickLink.seo && getQuickLink.seo.description == ''">Meta Description</div>
          <div class="description" v-else-if="getQuickLink.seo">{{ getQuickLink.seo.description }}</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  props: ['location'],
  data () {
    return {
      size: '14px',
      colorBlue: '#2d99ec',
      edit_slug: false,
      active_input: false,
      invalidPassword: false,
      isPermission: false,
      showLinkExpiry: false,
      showCustomMeta: false,
      showCustomFavicon: false,
      isUpdate: true,
      invalidFavicon: false,
      oldSlug: ''
    }
  },
  computed: {

    ...mapGetters([
      'getQuickLink',
      'getLinksLoaders',
      'getQuickLinkTag',
      'getLoaders'
    ])
  },
  mounted () {
    if (window.localStorage.getItem('access_password_protected') === 'true') {
      this.isPermission = true
    }
  },
  methods: {
    ...mapActions([
      'storeQuickLink',
      'uploadCustomMetaImage',
      'uploadCustomFaviconImage'
    ]),
    editQuickLinkSlug () {
      this.oldSlug = this.getQuickLink.shorten_url
      var self = this
      setTimeout(function () {
        $('.editable_url').focus()
        self.edit_slug = true
      }, 200)
    },
    async editSlugForQuickLink () {
      if (this.oldSlug === this.getQuickLink.shorten_url) {
        this.edit_slug = false
      } else if (this.edit_slug) {
        let res = await this.$store.dispatch('updateSlug', true)

        if (res.data.status) {
          this.$store.dispatch('toastNotification', { message: 'Your link slug has been updated' })
          this.edit_slug = false
        }
      }
    },
    close () {
      this.$parent.$bvModal.hide('quickLinkModal')
    },
    setLinkExpiry () {
      this.showLinkExpiry = !this.showLinkExpiry
      if (this.showLinkExpiry) {
        if (this.getQuickLink.expiry) {
          this.getQuickLink.link_expire_attributes.expiry = new Date()
        }
        this.getQuickLink.expiry = {
          expiry: new Date()
        }
      } else {
        this.getQuickLink.link_expire_attributes.expiry = null
      }
    },
    setCustomMeta () {
      this.showCustomMeta = !this.showCustomMeta
      if (this.showCustomMeta) {
        if (this.getQuickLink.seo) {
          this.getQuickLink.seo.title = ''
          this.getQuickLink.seo.description = ''
          this.getQuickLink.seo.image = ''
        }
        this.getQuickLink.seo = {
          title: '',
          description: '',
          image: ''
        }
      } else {
        this.getQuickLink.seo = null
      }
    },
    showUpdateButton () {
      if (this.getQuickLink.password_protected && this.getQuickLink.password && this.getQuickLink.password.length > 0) {
        this.isUpdate = true
      } else if (this.getQuickLink.link_expire_attributes.expiry) {
        this.isUpdate = true
      } else if (this.getQuickLink.seo) {
        this.isUpdate = true
      } else {
        this.isUpdate = false
      }
    },
    async update () {
      await this.storeQuickLink()
    },
    copyUrlName () {
      let testingCodeToCopy = document.querySelector('#copy-url')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.onCopy()
      } catch (err) {
        this.onError()
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  },
  watch: {
    'getQuickLink.favicon_url' (value) {
      this.invalidFavicon = value && value.length > 0 && !this.isValidURL(value)
    },
  }
})
</script>

<style lang="less" scoped>
.link_block i {
  cursor: pointer;
}

.dashboard_component .shorten_form_block .shorten_form_block_inner {
  border: 0 !important;
}

.another_link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.editable_url {
  width: 150px;
  display: inline-block;
  height: 0;
  border: 0;
  padding: 10px 0px;

  &:focus {
    border: none !important;
  }
}
</style>

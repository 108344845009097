<template>
  <div class="performance_charts simple-bar-chart pt-4">
    <div class="white_box ">
      <div class="box_inner">
        <div class="box_head justify-content-between d-flex align-items-center">
          <div class="left">
            <h2>{{ title }}</h2>
          </div>
          <div class="right">
            <h2>UNIQUES</h2>
          </div>
        </div>
        <div class="box_content" v-if="loading">
          <clip-loader :color="'#3988fe'" :size="'32px'"></clip-loader>
        </div>
        <div v-else-if="filterStats.length" :class="{'box_content_list':title !=='Referrals'}" class="box_content">
          <div v-for="item of filterStats" class="row chart_section">
            <div class="col-lg-10 col-md-10 mb-2">
              <div class="bars d-flex align-items-center" :style="{width: item.barLength + '%'}">
                <div class="d-flex align-items-center">
                  <img v-if="item.is_flag" class="mr-2" :src="computeCountryFlags(item.key)" style="width: 28px;"/>
                  <img v-else :src="item.image" class="mr-2" style="width: 30px;">
                  <p :class="{'text-capitalize':title !=='Referrals'}">{{ computeKeyAnalytics(item.key) }}</p>
                </div>
              </div>
            </div>
            <div class="analytics-type col-md-2 mb-2 text-right col-lg-2">{{ item.doc_count }}</div>
          </div>
        </div>
        <div v-else class="box_content box_content_list">
          <div class="no-event-available">
            <no-events-available></no-events-available>
          </div>
        </div>
        <div class="d-flex justify-content-between footer box_content">
          <div v-if="stats && stats.length > paginationChunk" class="d-flex align-items-center search-section">
            <a @click="showSearch = !showSearch" v-if="!showSearch"><i class="fas fa-search"></i></a>
            <a @click="showSearch = !showSearch" v-if="showSearch"><i class="fas fa-times-circle"></i></a>
            <div v-if="showSearch" class="input_icon_text ml-2 d-flex align-items-center profile__box">
              <div class="content">
                <div class="name">
                  <div class="validation_input">
                    <input v-model="search" placeholder="Search" type="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="stats && stats.length > paginationChunk" class="d-flex align-items-center pagination-section">
            <a v-if="!(paginationObj.current <= 0)" @click="pagination(false)"><i
              class="fas fa-arrow-left"></i></a>
            <a v-if="showNext" @click="pagination()"><i
              class="fas ml-2 fa-arrow-right"></i> </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SimpleBarChart',
  data() {
    return {
      showSearch: false,
      search: '',
      paginationObj: {
        current: 0,
        next: 0,
        previous: 0,
        chunk: 0
      },
      filterStats: [],
      showNext: false,
      showPrevious: false
    }
  },
  props: [
    'title',
    'stats',
    'paginationChunk',
    'loading'
  ],
  created() {
    this.init()
  },
  methods: {
    init(stats = this.stats) {
      this.filterStats = stats
      this.paginationObj.next = this.paginationChunk
      this.paginationObj.chunk = this.paginationChunk
      this.filterStats = stats.slice(this.paginationObj.current, this.paginationObj.chunk)
      this.showNext = stats.length > this.filterStats.length
    },
    searchStats() {
      this.resetPagination()
      if (!this.search && !this.search.length) {
        return this.filterStats = this.stats.slice(this.paginationObj.current, this.paginationObj.next)
      }
      this.filterStats = this.stats.filter(item => item.key.toLowerCase().includes(this.search))
      if (this.filterStats && !(this.filterStats.length > this.paginationObj.chunk)) {
        this.showNext = false
        return
      }
      this.filterStats = this.filterStats.slice(this.paginationObj.current, this.paginationObj.next)
    },
    pagination(next = true) {
      let filterLength = 0
      let filterStats = this.stats
      if (this.search.length) {
        filterStats = this.stats.filter(item => item.key.toLowerCase().includes(this.search))
      }
      filterLength = filterStats.length
      if (next) {
        if (!this.showNext) return
        this.paginationObj.current = this.paginationObj.next
        this.paginationObj.next = this.paginationObj.next + this.paginationObj.chunk
      } else {
        if (this.paginationObj.current <= 0) return
        this.paginationObj.next = this.paginationObj.next - this.paginationObj.chunk
        this.paginationObj.current = this.paginationObj.next - this.paginationObj.chunk
      }
      this.filterStats = filterStats.slice(this.paginationObj.current, this.paginationObj.next)
      this.showNext = this.paginationObj.next < filterLength
    },
    resetPagination() {
      this.paginationObj = {
        current: 0,
        next: this.paginationChunk,
        previous: 0,
        chunk: this.paginationChunk
      }
      this.filterStats = this.stats.slice(this.paginationObj.current, this.paginationObj.next)
      this.showNext = this.stats.length > this.filterStats.length
    }
  },
  watch: {
    'stats'(value) {
      this.init(value)
    },
    'search'(value) {
      this.searchStats()
    }
  }
}
</script>
<style lang="less">
.simple-bar-chart {
  width: 101%;
  min-width: 0%;

  .white_box {
    .box_inner {
      .box_head {
        .right {
          h2 {
            font-size: 13px;
            font-weight: bold;
            color: #676887;
          }
        }
      }

      .chart_section {
        padding: 0rem 0.5rem;

        .bars {
          height: 50px;
          padding: 1rem;
          border-radius: 10px;
          background-color: #D9F2FB;
          color: #1f216e;
          font-size: 12px;
          font-weight: 600;

          p {
            width: 30rem;
          }
        }

        .analytics-type {
          padding: 1rem 1rem;
          color: #1f216e;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .footer {
        height: 70px;

        .search-section {
          .fa-search, .fa-times-circle {
            font-size: 15px;
            color: #2c30b7;
            cursor: pointer;
          }
        }

        .pagination-section {
          .fa-arrow-right, .fa-arrow-left {
            font-size: 15px;
            color: #2c30b7;
            cursor: pointer;
          }
        }
      }

      .box_content_list {
        height: 320px;
      }

      .box_content {
        .no-event-available {
          padding-left: 20%;
          padding-right: 20%;
        }
      }
    }
  }
}
</style>

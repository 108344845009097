<template>
  <div class="conversions_performance filter-bar-chart pt-4">
    <div class="white_box ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center justify-content-between">
          <div class="d-flex justify-content-between left">
            <a @click="changeTab('Referrers')"><h2>Referrers</h2></a>
            <a @click="changeTab('Refs')"><h2>Refs</h2></a>
          </div>
          <div class="d-flex justify-content-between right">
            <h2>VIEWS</h2>
            <h2>UNIQUES</h2>
          </div>
        </div>
        <div class="tab" :class="{'tab-left':shiftTabs}"></div>
        <div v-if="searchStats() && searchStats().length" class="box_content">
          <div class="row chart_section">
            <div class="col-lg-10 col-md-10 mb-2">
              <div class="bars">Desktop</div>
            </div>
            <div class="analytics-type col-md-1 mb-2 text-right col-lg-1">12</div>
            <div class="analytics-type col-md-1 mb-2 text-right col-lg-1">12</div>
          </div>
        </div>
        <div v-else class="box_content">
          <div class="no-event-available">
            <no-events-available></no-events-available>
          </div>
        </div>
        <div class="d-flex justify-content-between footer box_content">
          <div class="d-flex align-items-center search-section">
            <a @click="showSearch = !showSearch" v-if="!showSearch"><i class="fas fa-search"></i></a>
            <a @click="showSearch = !showSearch" v-if="showSearch"><i class="fas fa-times-circle"></i></a>
            <div v-if="showSearch" class="input_icon_text ml-2 d-flex align-items-center profile__box">
              <div class="content">
                <div class="name">
                  <div class="validation_input">
                    <input v-model="search" placeholder="Search" type="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-section">
            <i class="fas fa-arrow-right"></i>
            <i class="fas fa-arrow-left"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilterBarChart',
  data () {
    return {
      shiftTabs: false,
      showSearch: false,
      search: ''
    }
  },
  props: [
    'title',
    'stats'
  ],
  mounted: function () {},
  computed: {},
  methods: {
    changeTab (type) {
      this.shiftTabs = !this.shiftTabs
    },
    searchStats(){
      if(this.search && this.search.length) return this.stats.filter(item => item.key.toLowerCase().includes(this.search))
      return this.stats
    }
  },
  watch: {
    'search' (value) {
      this.searchStats()
    }
  }
}
</script>
<style lang="less">
.filter-bar-chart {
  .white_box {
    .box_inner {
      .box_head {
        .left {
          width: 140px;

          a {
            cursor: pointer;
          }
        }

        .right {
          width: 9rem;

          h2 {
            font-size: 13px;
            font-weight: bold;
            color: #676887;
          }
        }
      }

      .tab {
        width: 25px;
        height: 5px;
        position: absolute;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        margin-left: 40px;
        margin-top: -6px;
        background-color: #2c30b7;
      }

      .tab-left {
        margin-left: 129px;
      }

      .chart_section {
        padding: 0rem 0.5rem;

        .bars {
          height: 50px;
          padding: 1rem;
          border-radius: 10px;
          background-color: #D9F2FB;
          color: #1f216e;
          font-size: 12px;
          font-weight: 600;
        }

        .analytics-type {
          padding: 1rem 1rem;
          color: #1f216e;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .footer {
        .search-section {
          .fa-search, .fa-times-circle {
            font-size: 15px;
            color: #2c30b7;
            cursor: pointer;
          }
        }

        .pagination-section {
          .fa-arrow-right {
            font-size: 18px;
            color: #2c30b7;
            cursor: pointer;
          }
        }
      }

      .box_content {
        .no-event-available {
          padding-left: 20%;
          padding-right: 20%;
        }
      }
    }
  }
}
</style>

<template>

    <div class="dashboard_boxes cta_creation_step clear get_extension_box mb-3"
         :class="{'d-none': getProfile.triggers.chrome_extension_dismissed}"
         v-if="!getProfile.triggers.chrome_extension_dismissed">
        <i @click.prevent="$store.dispatch('storeTrigger', 'chrome_extension_dismissed')"
           class="close_icon_chrome fal fa-times"></i>
        <div class="dashboard_boxes_inner">
            <div class="box_inner clear align_center">
                <div class="logo_chrome">
                    <img src="img/chrome.png" alt="">
                </div>
                <p>Our chrome extension helps you create {{ getSiteDetails.agency_name }} links on the go. Save time and increase productivity!
                </p>
                <div>
                    <a target="_blank"
                       href="https://chrome.google.com/webstore/detail/replug/mofbjpedmdgclbmpgjoboblbppnmgpol"
                       class="btn---cta btn-blue btn-round " style="background:#fff;" @click.prevent="downloadCE()">Download
                        Now</a>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hideChromeExtensionNotificationURL } from '../../../config/urls/user/user'

export default ({
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods:
      {
        hideExtensionNotification () {
          this.$http.post(hideChromeExtensionNotificationURL).then(
            response => {
              if (response.data.status) {
                this.setChromeExtensionStatus(true)
              } else {
                // pass

              }
            },
            response => {

            }
          )
        },
        downloadCE () {
          this.$store.dispatch('storeTrigger', 'chrome_extension_dismissed')
          window.open('https://chrome.google.com/webstore/detail/replug/mofbjpedmdgclbmpgjoboblbppnmgpol', '_blank')
        }
      }
})
</script>

<template>
    <div class="sidebar">
        <div class="left_region">
            <ul>
                <li>
                    <router-link :to="{'path': 'dashboard'}">
                        <i class="far fa-tachometer fa-2x" aria-hidden="true"></i>
                    </router-link>
                </li>
                <li>

                    <router-link :to="{'path': 'campaigns'}">
                         <i class="fab fa-bandcamp fa-2x" aria-hidden="true"></i>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{'path': 'shouts'}">
                         <i class="far fa-link fa-2x" aria-hidden="true"></i>
                    </router-link>
                </li>

            </ul>
        </div>
    </div>

</template>

<script>
export default (
  {
    'name': 'Sidebar'
  }
)
</script>
